/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from "./Dash";

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '',
      component: Dash,
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "about" */ './views/Home/Home.vue'),
          meta: { title: 'Home', canonical: '/' }
        },
        {
          path: '/dr_guilherme',
          name: 'dr_guilherme',
          component: () => import(/* webpackChunkName: "about" */ './views/DrGuilherme/DrGuilherme.vue'),
          meta: { title: 'Dr. Guilherme', canonical: '/dr_guilherme' }
        },
        {
          path: '/doenca',
          name: 'doenca',
          component: () => import(/* webpackChunkName: "about" */ './views/Doencas/Doencas.vue'),
          meta: { title: 'Doenças', canonical: '/doenca' }
        },
        {
          path: '/doenca_detalhe/:doenca_id',
          name: 'doenca_detalhe',
          component: () => import(/* webpackChunkName: "about" */ './views/Doencas/DoencaDetalhes.vue'),
          meta: { title: 'Detalhe da Doença', canonical: '/doenca_detalhe/:doenca_id' }
        },
        {
          path: '/doppler_vascular',
          name: 'doppler_vascular',
          component: () => import(/* webpackChunkName: "about" */ './views/DopperVasculares/DopperVasculares.vue'),
          meta: { title: 'Doppler Vascular', canonical: '/doppler_vascular' }
        },
        {
          path: '/convenios',
          name: 'convenios',
          component: () => import(/* webpackChunkName: "about" */ './views/Convenios/Convenios.vue'),
          meta: { title: 'Convênios', canonical: '/convenios' }
        },
        {
          path: '/blog',
          name: 'blog',
          component: () => import(/* webpackChunkName: "about" */ './views/Blog/Blog.vue'),
          meta: { title: 'Blog', canonical: '/blog' }
        },
        {
          path: '/blog/:blog_id',
          name: 'Artigo',
          component: () => import(/* webpackChunkName: "about" */ './views/Blog/Artigo.vue'),
          meta: { title: 'Artigo', canonical: '/blog/:blog_id' }
        },
        {
          path: '/atendimento',
          name: 'Atendimento',
          component: () => import(/* webpackChunkName: "about" */ './views/Atendimento/Atendimento.vue'),
          meta: { title: 'Atendimento', canonical: '/atendimento' }
        },
        {
          path: '/contato',
          name: 'Contato',
          component: () => import(/* webpackChunkName: "about" */ './views/Contato/Contato.vue'),
          meta: { title: 'Contato', canonical: '/contato' }
        },
      ]
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "about" */ './views/404.vue'),
      meta: { title: 'Página não encontrada', canonical: '/404' }
    }
  ]
});

router.beforeEach((to, from, next) => {
  document.title = to.meta && to.meta.title ? to.meta.title + ' - Dr.Guilherme' : 'Dr.Guilherme';
  next();
});

router.afterEach((to) => {
  const linkCanonical = document.querySelector("link[rel='canonical']");
  // const canonicalUrl = window.location.pathname||to.meta?.canonical;
  const canonicalUrl = window.location.origin + ( window.location.pathname||to.meta?.canonical);
  if (linkCanonical) {
    linkCanonical.setAttribute("href", canonicalUrl);
  } else {
    const link = document.createElement("link");
    link.setAttribute("rel", "canonical");
    link.setAttribute("href", canonicalUrl);
    document.head.appendChild(link);
  }
});
export default router
