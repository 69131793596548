/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import usuario from './modules/usuarios'
import blog from './modules/blog'
import doenca from './modules/doenca'
import contato from './modules/contatos'
import instagram from './modules/instagram'
import banner from './modules/banner'
import local_atendimento from './modules/local_atendimento'
import geral from './modules/geral'


Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    blog,
    banner,
    doenca,
    usuario,
    contato,
    instagram,
    local_atendimento,
    geral

  }
})
