const state = {
  tab: '0' | sessionStorage.getItem('tab'),
}
const getters = {
  getTab: (state) => {
    return state.tab
  },
}
const actions = {
  setTab({ commit }, tab) {
    sessionStorage.setItem('tab',JSON.stringify(tab))
    commit('SET_TAB', tab);
  },

}
const mutations = {
  SET_TAB (state, payload) {
    state.tab = payload
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
