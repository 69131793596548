/* eslint-disable */
import {mapActions} from 'vuex'

export default {
  data: () => ({
    listaItens: [],
    array_scroll: []
  }),
  methods: {
    ...mapActions('geral', ['setTab']),
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    itens(item) {
      this.listaItens = item
    },
    cortarString(str, tam) {
      if (this.validarCampo(str)) {
        if (str.length > tam) {
          str = str.slice(0, tam) + '...'
        }
      }
      return str
    },
    // MUDA COR DA LINHA
    isActive(id) {
      var toobar_dash = document.getElementsByClassName('toobar_dash')
      var lista = toobar_dash.item(0).children
      for(var i=0; i< lista.length; i++){
        var classes = lista[i].className.split(' ')
        var getIndex = classes.indexOf('toobar_is_active')

        if (lista[i].id === id){
          if (getIndex === -1) {
            classes.push('toobar_is_active')
            lista[i].className = classes.join(' ')
          }
        } else {
          if (getIndex > -1) {
            classes.splice(getIndex, 1)
            lista[i].className = classes.join(' ')
          }
        }
      }
    },
    focusInput(id) {
      let loadTime = 10;
      window.onload = function () {
        loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
      };
      let interval = setInterval(function () {
        let muniImpt = document.getElementById(id);
        if (muniImpt !== null) {
          muniImpt.focus()
        }
        clearInterval(interval)
      }, loadTime)
    },
    chunkArray(myArray, n) {
      let tempArray = []
      let chunksize = Math.ceil(Math.max(myArray.length / n, 1))
      for (let index = 0; index < myArray.length; index += chunksize) {
        tempArray.push(myArray.slice(index, index + chunksize))
      }
      return tempArray
    },
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    scroll_top(){
      var elmnt = document.getElementById("inspire");
      elmnt.scrollTop = 0
    },
    scrollTo (id='inspire') {
      var elmnt = document.getElementById(id);
      elmnt.scrollIntoView({behavior: "smooth"});
    },
    inverterData2(newData) {
      var xvals = ''
      if (this.validarCampo(newData)) {
        xvals = newData.split('-')
        return xvals[2] + '/' + xvals[1] + '/' + xvals[0]
      } else {
        return xvals
      }
    },
    // async setAnimateScroll({ commit }, id) {
    //   var ret = await this.AnimateScroll(id)
    //   console.log('ret: ', ret)
    // },
    writeHello(id,callback) {
      console.log('Hello World:', id);
      console.log('callback:', callback);
      if (typeof callback == 'function') callback();
    },
    tempo() {
      // var temp = 1000000 * 60
      // for(var i = 1; i<= temp; i++){
      //   if (i>= temp){
      //     return true
      //   }
      // }
      // return false

    },
    retira_acentos(str)
    {

      var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ!"

      var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr "
      var novastr="";
      for(var i=0; i<str.length; i++) {
        var troca=false;
        for (var a=0; a<com_acento.length; a++) {
          if (str.substr(i,1)===com_acento.substr(a,1)) {
            novastr+=sem_acento.substr(a,1);
            troca=true;
            break;
          }
        }
        if (troca===false) {
          novastr+=str.substr(i,1);
        }
      }
      return novastr;
    },
    AbriEmNovaAba(path){
      var a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      a.click()
    },
    recarregar(path){
      var a = document.createElement('a')
      a.href = path
      a.click()
    }

  },

}
