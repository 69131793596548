<template>
  <div id="inspire">
    <v-layout>
      <v-card class="toobar" style="background: #EFEFEF!important; width: 100%; border-radius: 0px" flat>
        <!--DESKTOP-->
        <v-app-bar absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7"
                   class="appScrolling" id="navapp">
          <v-system-bar dark :height="30" window fixed id="system_bar">
            <v-row style="padding: 0px">
              <v-col cols="12" sm="6"  style="text-align: center">
                <small style="width: 37%">
              <span style="padding: 2%;">
                <a href="https://api.whatsapp.com/send?phone=+3231122295" target="_blank" style="text-decoration: none; color: inherit">
                               <v-icon>mdi-whatsapp</v-icon> (32) 3112-2295
                </a>
               </span>
               <span style="padding-left: 2%; padding-right: 2%">
                <a href="tel:3230268550" style="text-decoration: none; color: inherit">
                   <v-icon >mdi-phone</v-icon>  (32) 3112-0697
                </a>
              </span>
                  <span style="padding-left: 2%; padding-right: 2%"><v-icon>mdi-email</v-icon> guilherme.vascular@gmail.com</span>
                </small>
              </v-col>

              <v-col cols="12" sm="6" class="spc"  style="padding: 5px; text-align: right">
                <v-icon aria-label="facebook" @click="AbriEmNovaAba('https://www.facebook.com/dr.guilhermebicalho')" style="margin: 1%; color: #3D60B0!important;">
                  mdi-facebook
                </v-icon>
                <v-icon aria-label="instagram" @click="AbriEmNovaAba('https://www.instagram.com/dr.guilhermebicalho/')" style="margin: 1%; color: #CE2861!important;">
                  mdi-instagram
                </v-icon>
              </v-col>
            </v-row>
          </v-system-bar>

          <v-app-bar-nav-icon class="ismobile" @click="AtivarMobile()" small></v-app-bar-nav-icon>

          <!--<v-container style="margin-top: 50px">-->
            <!--<v-toolbar-title>-->
              <!--<div @click="irPara('/')" id="home" style="cursor: pointer">-->
                <!--<div class="div_logo"></div>-->
              <!--</div>-->
            <!--</v-toolbar-title>-->
          <!--</v-container>-->
          <!--<v-spacer></v-spacer>-->
          <!--<v-toolbar-items class="toobar_dash" style="margin-top: 50px">-->
            <!--<v-btn text @click="irPara('/dr_guilherme', 'dr_guilherme')" id="dr_guilherme">Dr Guilherme</v-btn>-->
            <!--<v-btn text @click="irPara('/atendimento', 'atendimento')" id="atendimento">Locais de Atendimento</v-btn>-->
            <!--<v-btn text @click="irPara('/doenca', 'doenca_vascular')" id="doenca_vascular">Doenças</v-btn>-->
            <!--<v-btn text @click="irPara('/doppler_vascular', 'doppler_vascular')" id="doppler_vascular">Doppler Vascular</v-btn>-->
            <!--<v-btn text @click="irPara('/convenios', 'convenios')" id="convenios">Convênios</v-btn>-->
            <!--<v-btn text @click="irPara('/blog', 'blog')" id="blog">Blog</v-btn>-->
            <!--<v-btn text @click="irPara('/contato', 'Contato')" id="Contato">Contato</v-btn>-->
          <!--</v-toolbar-items>-->

          <!--style="padding-left: 2%; padding-right: 2%;"  class="toobar_dash"-->

          <v-tabs id="tab_home" v-model="tabAux" :hide-slider="tabAux===0" right slider-color="red"
                  style="margin-top: 60px; margin-right: 1%">
            <v-tab  @click="irPara('/','/','0')" style="left:10% ">
                <v-img :src="logo"></v-img>
            </v-tab>

            <v-spacer></v-spacer>

            <v-tab @click="irPara('/dr_guilherme','dr_guilherme',1)" class="tab_desktop">Dr Guilherme</v-tab>
            <v-tab @click="irPara('/atendimento','atendimento',2)" class="tab_desktop">Locais de Atendimento</v-tab>
            <v-tab @click="irPara('/doenca','doenca_vascular',3)" class="tab_desktop">Doenças</v-tab>
            <v-tab @click="irPara('/doppler_vascular','doppler_vascular',4)" class="tab_desktop">Doppler Vascular</v-tab>
            <v-tab @click="irPara('/convenios','convenios',5)" class="tab_desktop">Convenios</v-tab>
            <v-tab @click="irPara('/blog','blog',6)" class="tab_desktop">Blog</v-tab>
            <v-tab @click="irPara('/contato','contato',7)" class="tab_desktop">Contato</v-tab>
          </v-tabs>

        </v-app-bar>

        <!--MOBILE-->
        <v-navigation-drawer v-model="drawer" absolute temporary style="background: rgba(255,255,255,0.9)">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Menu</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-list dense>
            <v-list-item v-for="item in listaMenu" :key="item.path" link @click="irPara2(item.path, item.path)">
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

        <!--CONTEUDO-->
        <v-sheet class="body" id="scrolling-techniques-7">
          <div>
            <router-view/>
          </div>
          <br>
          <footers></footers>
        </v-sheet>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
/* eslint-disable */
  import router from './router'
  import miscMixin from './helpers/misc'
import logo from './assets/img/oficial/logo.svg'
import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Dash',
    mixins: [miscMixin],
    data: () => ({
      logo,
      drawer: null,
      collapseOnScroll: false,
      delay: '800',
      listaMenu: [
        {title: 'Home', path: ''},
        {title: 'Dr Guilherme', path: 'dr_guilherme'},
        {title: 'Locais de atendimento', path: 'atendimento'},
        {title: 'Doenças', path: 'doenca'},
        {title: 'Doppler Vascular', path: 'doppler_vascular'},
        {title: 'Convênios', path: 'convenios'},
        {title: 'Blog', path: 'blog'},
        {title: 'Contato', path: 'contato'},
      ]
    }),
    components: {
      Footers: () => import('./components/Footer')
    },
    methods: {
      ...mapActions('geral', ['setTab']),
      AtivarMobile() {
        this.drawer= !this.drawer
      },
      irPara(path, id, tab=10) {
        this.setTab(tab)
        this.scrollTo('inspire')
        // this.isActive(id)
        router.push({path: path}).catch(err => {
        })
      },
      irPara2(path, id) {
        this.scrollTo('inspire')
        router.push({path: '/'+ path}).catch(err => {
        })
      },
    },
    mounted(){

      window.addEventListener('popstate', function(event) {
        var cortar = window.location.pathname.replace('/', '')
        var a = cortar.split('/')
        if (a.length === 2) {
          window.location.reload()
        }

      }, true)


      // Permite usar o AOS com vuetify
      var inspire = document.getElementById('inspire')
      window.onscroll = function() {
        'use strict'
        if (document.documentElement.scrollTop >= 50) {
          inspire.classList.add('scroll')
        } else {
          inspire.classList.remove('scroll')
        }
      }
      var width = window.screen.width
      if (width <= 500) {
        this.collapseOnScroll = true
      }
    },
    computed: {
      ...mapGetters('geral', {tab: 'getTab'}),
      tabAux: {
        get () {
          return this.tab
        },
        set () {
          return this.tabAux
        }
      },
    }
  }
</script>
